




































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Scaffold',
  data() {
    return {
      showAccountMenu: false,
    };
  },
  props: {
    currentSession: {
      type: Object,
      required: false,
      default() {
        return {
          name: null,
        };
      },
    },
    elapsedTime: {
      type: String,
      required: false,
      default: '',
    },
    remainingTime: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.auth.getUser`
    ...mapGetters({
      user: 'auth/getUser',
      userTheme: 'auth/getUserTheme',
    }),
  },
  methods: {
    async signOut(): Promise<void> {
      await this.$store.dispatch('auth/signOut');
    },
    goToHome(): void {
      this.$router.push({
        name: 'Presenter Home',
      });
    },
    goToNewSession(): void {
      this.$router.push({
        name: 'New Presenter Session',
      });
    },
    goToProfile(): void {
      this.$router.push({
        name: 'Profile',
      });
    },
    goToPastSessions(): void {
      this.$router.push({
        name: 'Past Sessions',
      });
    },

    pauseSession() {
      if (this.currentSession) {
        if (this.currentSession.isPaused) {
          if (
            confirm(
              this.$t('components.presenter.scaffoldSessionContinue') as string
            )
          ) {
            this.$store.dispatch('presenter/pauseSession', {
              sessionId: this.currentSession.id,
              state: false,
            });
          }
        } else {
          if (
            confirm(
              this.$t('components.presenter.scaffoldSessionContinue') as string
            )
          ) {
            this.$store.dispatch('presenter/pauseSession', {
              sessionId: this.currentSession.id,
              state: true,
            });
          }
        }
      }
    },
  },
});
