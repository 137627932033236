










import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { formatDateTime, millisToTimeString } from '@/utils/tools';
import Scaffold from '@/components/Presenter/Scaffold.vue';

export default Vue.extend({
  components: {
    Scaffold,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    await this.$store.dispatch('viewer/fetchSessionTheme', {
      sessionUserGroup: this.currentSession.userGroup,
    });
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
    }),
    elapsedTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt &&
        !this.currentSession.isPaused
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const elapsedTime =
            this.now.getTime() - this.currentSession.startAt.toDate().getTime();
          return millisToTimeString(elapsedTime);
        } else if (this.now > this.currentSession.endAt.toDate()) {
          return this.$t('views.presenter.sessionEnded') as string;
        }
        return (
          (this.$t('views.presenter.sessionStartsAt') as string) +
          `${formatDateTime(this.currentSession.startAt.toDate())}`
        );
      }
      if (this.currentSession.isPaused)
        return this.$t('views.presenter.sessionPaused') as string;
      return '-';
    },
    remainingTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt &&
        !this.currentSession.isPaused
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const remainingTime =
            this.currentSession.endAt.toDate().getTime() - this.now.getTime();
          return millisToTimeString(remainingTime);
        }
      }
      return '';
    },
  },
  methods: {
    ...mapMutations({
      reset: 'presenter/RESET',
    }),
  },
  beforeDestroy() {
    this.reset();
  },
});
